import React, { useState, useEffect } from 'react';
import moment from 'moment';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

import { eventTileHazards } from '../constants/eventHazards.js';
import {
  sampleDrawLayer,
  samplePointLayer,
  sampleLineLayer,
} from '../constants/sampleLayers';

const polygon_events_layer_id = 'polygon_events_layer_id';
const point_events_layer_id = 'point_events_layer_id';
const polygon_events_source_id = 'polygon_events_source_id';
const point_events_source_id = 'point_events_source_id';
const polygon_source_layer = 'public.polygon_events_layer';
const point_source_layer = 'public.point_events_layer';

const hurricane_cones_layer = 'public.hurricane_cones';
const hurricane_points_layer = 'public.hurricane_points';
const hurricane_paths_layer = 'public.hurricane_paths';
const hurricane_cones_layer_id = 'public.hurricane_cones_layer_id';
const hurricane_points_layer_id = 'public.hurricane_points_layer_id';
const hurricane_paths_layer_id = 'public.hurricane_paths_layer_id';
const hurricane_cones_source_id = 'public.hurricane_cones_source_id';
const hurricane_points_source_id = 'public.hurricane_points_source_id';
const hurricane_paths_source_id = 'public.hurricane_paths_source_id';

const earthquakes_source_layer = 'earthquakes_source_layer';
const earthquakes_clusters_layer_id = 'earthquakes_clusters_layer_id';
const earthquakes_cluster_count_layer_id = 'earthquakes_cluster_count_layer_id';
const earthquakes_unclustered_point_layer_id =
  'earthquakes_unclustered_point_layer_id';

const polygon_poweroutage_layer_id = 'polygon_poweroutage_layer_id';
const polygon_poweroutage_source_id = 'polygon_poweroutage_source_id';
const polygon_poweroutage_source_layer =
  'public.polygon_poweroutage_source_layer';

const eventTypesToMapboxColorExpression = () => {
  const expression = [
    'match',
    ['get', 'hazard_name'],
    ...eventTileHazards
      .map((t) => {
        return [t.hazard_name, t.hazard_color];
      })
      .flat(),
    'Wild Fires',
    '#FF0000',
    '#FFFF00' /* other */,
  ];
  return expression;
};

function hurricaneLayerTypesToMapboxColorExpression() {
  const ex1 = [
    'step',
    ['get', 'unified_speed'],
    '#0099FF',
    33,
    '#81C056',
    63,
    '#F6C445',
    82,
    '#EE7932',
    95,
    '#D64A27',
    112,
    '#AC32F6',
    136,
    '#D535A9',
  ];

  return ex1;
}

export default function mapEventsEffect(
  map,
  mapHasLoaded,
  ethelData,
  eventsVisibleToggle,
  selectedDatetime,
  setLayerClicked,
  apiPrefix,
  earthquakesData,
  poweroutageData,
  poweroutageActive,
  eventToggles,
) {
  if (!mapHasLoaded || !earthquakesData) return;

  if (
    !!map.current
      .getStyle()
      .layers.find((l) => l.id === polygon_events_layer_id)
  ) {
    map.current.removeLayer(polygon_events_layer_id);
  }
  if (
    !!map.current.getStyle().layers.find((l) => l.id === point_events_layer_id)
  ) {
    map.current.removeLayer(point_events_layer_id);
  }
  if (!!map.current.getSource(polygon_events_source_id)) {
    map.current.removeSource(polygon_events_source_id);
  }
  if (!!map.current.getSource(point_events_source_id)) {
    map.current.removeSource(point_events_source_id);
  }

  if (
    !!map.current
      .getStyle()
      .layers.find((l) => l.id === polygon_poweroutage_layer_id)
  ) {
    map.current.removeLayer(polygon_poweroutage_layer_id);
  }
  if (!!map.current.getSource(polygon_poweroutage_source_id)) {
    map.current.removeSource(polygon_poweroutage_source_id);
  }

  if (
    !!map.current
      .getStyle()
      .layers.find((l) => l.id === hurricane_cones_layer_id)
  ) {
    map.current.removeLayer(hurricane_cones_layer_id);
  }

  if (
    !!map.current
      .getStyle()
      .layers.find((l) => l.id === hurricane_paths_layer_id)
  ) {
    map.current.removeLayer(hurricane_paths_layer_id);
  }

  if (
    !!map.current
      .getStyle()
      .layers.find((l) => l.id === hurricane_points_layer_id)
  ) {
    map.current.removeLayer(hurricane_points_layer_id);
  }

  if (!!map.current.getSource(hurricane_cones_source_id)) {
    map.current.removeSource(hurricane_cones_source_id);
  }
  if (!!map.current.getSource(hurricane_paths_source_id)) {
    map.current.removeSource(hurricane_paths_source_id);
  }
  if (!!map.current.getSource(hurricane_points_source_id)) {
    map.current.removeSource(hurricane_points_source_id);
  }

  if (
    !!map.current
      .getStyle()
      .layers.find((l) => l.id === earthquakes_clusters_layer_id)
  ) {
    map.current.removeLayer(earthquakes_clusters_layer_id);
  }
  if (
    !!map.current
      .getStyle()
      .layers.find((l) => l.id === earthquakes_cluster_count_layer_id)
  ) {
    map.current.removeLayer(earthquakes_cluster_count_layer_id);
  }
  if (
    !!map.current
      .getStyle()
      .layers.find((l) => l.id === earthquakes_unclustered_point_layer_id)
  ) {
    map.current.removeLayer(earthquakes_unclustered_point_layer_id);
  }

  if (!!map.current.getSource(earthquakes_source_layer)) {
    map.current.removeSource(earthquakes_source_layer);
  }

  if (!!eventsVisibleToggle) {
    // Try/catch to allow graceful handling (ie no crash) if the HTTP request returns an error
    try {
      let polygonTiles;

      polygonTiles = `${apiPrefix}/event_tiles/${polygon_source_layer}/{z}/{x}/{y}.pbf`;

      const formattedDatetime = selectedDatetime
        .utc()
        .format('YYYY-MM-DD HH:mm:ss');
      const endDatetime = dayjs(formattedDatetime)
        .utc()
        .format('YYYY-MM-DD HH:mm:ss');

      // Polygon tiles
      //NWS Alerts (and also Wildfires)
      if (
        !eventToggles ||
        eventToggles.some((t) => t.hazard_data_category === 'NWS alerts')
      ) {
        map.current.addSource(polygon_events_source_id, {
          type: 'vector',
          tiles: [
            polygonTiles +
              '?datetime_selected=' +
              formattedDatetime +
              '&end_datetime=' +
              endDatetime,
          ],
        });
      }

      const polygonEventsLayerConfig = {
        ...sampleDrawLayer,
        id: polygon_events_layer_id,
        source: polygon_events_source_id,
        'source-layer': polygon_source_layer,
        generateId: true,
        paint: {
          ...sampleDrawLayer.paint,
          'fill-color': eventTypesToMapboxColorExpression(),
        },
        metadata: {
          eventlayer: true,
          usermade: undefined,
        },
      };

      map.current.addLayer(polygonEventsLayerConfig);

      map.current.on('click', polygonEventsLayerConfig.id, (e) =>
        setLayerClicked({
          layer: polygonEventsLayerConfig,
          e: e,
          features: e.features,
        })
      );

      map.current.on('touchend', polygonEventsLayerConfig.id, (e) =>
        setLayerClicked({
          layer: polygonEventsLayerConfig,
          e: e,
          features: e.features,
        })
      );

      map.current.on('mouseenter', polygonEventsLayerConfig.id, () => {
        map.current.getCanvas().style.cursor = 'pointer';
      });
      map.current.on('mouseleave', polygonEventsLayerConfig.id, () => {
        map.current.getCanvas().style.cursor = 'grab';
      });

      // Power Outage

      if (poweroutageActive) {
        let polygonPoweroutageTiles;

        polygonPoweroutageTiles = `${apiPrefix}/power_outage_tiles/${polygon_poweroutage_source_layer}/{z}/{x}/{y}.pbf`;

        const formattedHoursDatetime = selectedDatetime
          .minute(0)
          .second(0)
          .utc()
          .format('YYYY-MM-DD HH:mm:ss');

        // Power Outage tiles

        if (
          !eventToggles ||
          eventToggles.some((t) => t.hazard_data_category === 'Power Outage')
        ) {
          map.current.addSource(polygon_poweroutage_source_id, {
            type: 'vector',
            tiles: [
              polygonPoweroutageTiles +
                '?datetime_selected=' +
                formattedHoursDatetime,
            ],
          });
        }

        const polygonPoweroutageLayerConfig = {
          ...sampleDrawLayer,
          id: polygon_poweroutage_layer_id,
          source: polygon_poweroutage_source_id,
          'source-layer': polygon_poweroutage_source_layer,
          generateId: true,
          paint: {
            ...sampleDrawLayer.paint,
            'fill-color': [
              'step',
              [
                '*',
                ['/', ['get', 'CustomersOut'], ['get', 'CustomersTracked']],
                100,
              ],
              '#FFFFFF',
              5,
              '#ffffcc',
              10,
              '#a1dab4',
              15,
              '#41b6c4',
              25,
              '#2c7fb8',
              35,
              '#253494',
            ],
          },
          metadata: {
            eventlayer: true,
            usermade: undefined,
          },
        };

        map.current.addLayer(polygonPoweroutageLayerConfig);

        map.current.on('click', polygonPoweroutageLayerConfig.id, (e) =>
          setLayerClicked({
            layer: polygonPoweroutageLayerConfig,
            e: e,
            features: e.features,
          })
        );

        map.current.on('touchend', polygonPoweroutageLayerConfig.id, (e) =>
          setLayerClicked({
            layer: polygonPoweroutageLayerConfig,
            e: e,
            features: e.features,
          })
        );

        map.current.on('mouseenter', polygonPoweroutageLayerConfig.id, () => {
          map.current.getCanvas().style.cursor = 'pointer';
        });
        map.current.on('mouseleave', polygonPoweroutageLayerConfig.id, () => {
          map.current.getCanvas().style.cursor = 'grab';
        });
      }

      // Earthquakes cluster
      if (
        !eventToggles ||
        eventToggles.some((t) => t.hazard_data_category === 'Earthquakes')
      ) {
        //checks toggles before adding source
        map.current.addSource(earthquakes_source_layer, {
          type: 'geojson',
          data: {
            type: 'FeatureCollection',
            features: earthquakesData || [],
          }, // 'https://docs.mapbox.com/mapbox-gl-js/assets/earthquakes.geojson',
          cluster: true,
          clusterMaxZoom: 14, // Max zoom to cluster points on
          clusterRadius: 50, // Radius of each cluster when clustering points (defaults to 50)
        });
        // }
      }

      const earthquakesClustersLayerConfig = {
        id: earthquakes_clusters_layer_id,
        source: earthquakes_source_layer,
        type: 'circle',
        filter: ['has', 'point_count'],
        paint: {
          'circle-color': [
            'step',
            ['get', 'point_count'],
            '#FFA500',
            20,
            '#FFA500',
            50,
            '#FFA500',
          ],
          'circle-radius': ['step', ['get', 'point_count'], 20, 20, 30, 50, 40],
          'circle-opacity': 0.5,
        },
        metadata: {
          eventlayer: true,
          usermade: undefined,
        },
      };

      const earthquakesClusterCounterLayerConfig = {
        id: earthquakes_cluster_count_layer_id,
        source: earthquakes_source_layer,
        type: 'symbol',
        filter: ['has', 'point_count'],
        layout: {
          'text-field': ['get', 'point_count_abbreviated'],
          'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Bold'],
          'text-size': 12,
        },
        metadata: {
          eventlayer: true,
          usermade: undefined,
        },
      };

      const earthquakesUnclusteredPointLayerConfig = {
        id: earthquakes_unclustered_point_layer_id,
        source: earthquakes_source_layer,
        type: 'circle',
        filter: ['!', ['has', 'point_count']],
        paint: {
          'circle-color': '#FFA500',
          'circle-radius': 4,
          'circle-stroke-width': 1,
          'circle-stroke-color': '#FFA500',
          'circle-opacity': 0.5,
        },
        generateId: true,
        metadata: {
          eventlayer: true,
          usermade: undefined,
        },
      };

      map.current.addLayer(earthquakesClustersLayerConfig);
      map.current.on('click', earthquakesClustersLayerConfig.id, (e) => {
        const features = map.current.queryRenderedFeatures(e.point, {
          layers: [earthquakesClustersLayerConfig.id],
        });
        const clusterId = features[0].properties.cluster_id;
        map.current
          .getSource(earthquakes_source_layer)
          .getClusterExpansionZoom(clusterId, (err, zoom) => {
            if (err) return;

            map.current.easeTo({
              center: features[0].geometry.coordinates,
              zoom: zoom,
            });
          });
      });
      map.current.on('mouseenter', earthquakesClustersLayerConfig.id, () => {
        map.current.getCanvas().style.cursor = 'pointer';
      });
      map.current.on('mouseleave', earthquakesClustersLayerConfig.id, () => {
        map.current.getCanvas().style.cursor = 'grab';
      });

      map.current.addLayer(earthquakesClusterCounterLayerConfig);

      map.current.addLayer(earthquakesUnclusteredPointLayerConfig);
      map.current.on(
        'click',
        earthquakesUnclusteredPointLayerConfig.id,
        (e) => {
          let features = Array.from(
            new Set(e.features.map((feature) => JSON.stringify(feature)))
          ).map((strFeature) => JSON.parse(strFeature));
          setLayerClicked({
            layer: earthquakesUnclusteredPointLayerConfig,
            e: e,
            features: features,
          });
        }
      );
      map.current.on(
        'touchend',
        earthquakesUnclusteredPointLayerConfig.id,
        (e) =>
          setLayerClicked({
            layer: earthquakesUnclusteredPointLayerConfig,
            e: e,
            features: e.features,
          })
      );
      map.current.on(
        'mouseenter',
        earthquakesUnclusteredPointLayerConfig.id,
        () => {
          map.current.getCanvas().style.cursor = 'pointer';
        }
      );
      map.current.on(
        'mouseleave',
        earthquakesUnclusteredPointLayerConfig.id,
        () => {
          map.current.getCanvas().style.cursor = 'grab';
        }
      );

      // Hurricanes

      let hurricaneConeTiles;
      let hurricanePathTiles;
      let hurricanePointTiles;

      hurricaneConeTiles = `${apiPrefix}/event_tiles/${hurricane_cones_layer}/{z}/{x}/{y}.pbf`;
      hurricanePathTiles = `${apiPrefix}/event_tiles/${hurricane_paths_layer}/{z}/{x}/{y}.pbf`;
      hurricanePointTiles = `${apiPrefix}/event_tiles/${hurricane_points_layer}/{z}/{x}/{y}.pbf`;

      const addSource = (sourceId, tileUrl) => {
        map.current.addSource(sourceId, {
          type: 'vector',
          tiles: [
            tileUrl +
              '?datetime_selected=' +
              formattedDatetime +
              '&end_datetime=' +
              endDatetime,
          ],
        });
      };
      if (
        !eventToggles ||
        eventToggles.some((t) => t.hazard_data_category === 'Hurricane')
      ) {
        addSource(hurricane_cones_source_id, hurricaneConeTiles);
        addSource(hurricane_paths_source_id, hurricanePathTiles);
        addSource(hurricane_points_source_id, hurricanePointTiles);
      }

      const hurricaneConesLayerConfig = {
        ...sampleDrawLayer,
        id: hurricane_cones_layer_id,
        source: hurricane_cones_source_id,
        'source-layer': hurricane_cones_layer,
        generateId: true,
        paint: {
          ...sampleDrawLayer.paint,
          'fill-color': '#FFFFFF',
        },
        metadata: {
          eventlayer: true,
          usermade: undefined,
        },
      };
      const hurricanePathsLayerConfig = {
        ...sampleLineLayer,
        id: hurricane_paths_layer_id,
        source: hurricane_paths_source_id,
        'source-layer': hurricane_paths_layer,
        generateId: true,
        paint: {
          ...sampleLineLayer.paint,
          'line-color': '#D3D3D3',
        },
        metadata: {
          eventlayer: true,
          usermade: undefined,
        },
      };
      const hurricanePointsLayerConfig = {
        ...samplePointLayer,
        id: hurricane_points_layer_id,
        source: hurricane_points_source_id,
        'source-layer': hurricane_points_layer,
        generateId: true,
        paint: {
          ...samplePointLayer.paint,
          'circle-color': hurricaneLayerTypesToMapboxColorExpression(),
          'circle-radius': 10,
        },
        metadata: {
          eventlayer: true,
          usermade: undefined,
        },
      };

      map.current.addLayer(hurricaneConesLayerConfig);
      map.current.on('click', hurricaneConesLayerConfig.id, (e) =>
        setLayerClicked({
          layer: hurricaneConesLayerConfig,
          e: e,
          features: e.features,
        })
      );
      map.current.on('touchend', hurricaneConesLayerConfig.id, (e) =>
        setLayerClicked({
          layer: hurricaneConesLayerConfig,
          e: e,
          features: e.features,
        })
      );
      map.current.on('mouseenter', hurricaneConesLayerConfig.id, () => {
        map.current.getCanvas().style.cursor = 'pointer';
      });
      map.current.on('mouseleave', hurricaneConesLayerConfig.id, () => {
        map.current.getCanvas().style.cursor = 'grab';
      });

      map.current.addLayer(hurricanePathsLayerConfig);
      map.current.on('click', hurricanePathsLayerConfig.id, (e) =>
        setLayerClicked({
          layer: hurricanePathsLayerConfig,
          e: e,
          features: e.features,
        })
      );
      map.current.on('touchend', hurricanePathsLayerConfig.id, (e) =>
        setLayerClicked({
          layer: hurricanePathsLayerConfig,
          e: e,
          features: e.features,
        })
      );
      map.current.on('mouseenter', hurricanePathsLayerConfig.id, () => {
        map.current.getCanvas().style.cursor = 'pointer';
      });
      map.current.on('mouseleave', hurricanePathsLayerConfig.id, () => {
        map.current.getCanvas().style.cursor = 'grab';
      });

      map.current.addLayer(hurricanePointsLayerConfig);
      map.current.on('click', hurricanePointsLayerConfig.id, (e) =>
        setLayerClicked({
          layer: hurricanePointsLayerConfig,
          e: e,
          features: e.features,
        })
      );
      map.current.on('touchend', hurricanePointsLayerConfig.id, (e) =>
        setLayerClicked({
          layer: hurricanePointsLayerConfig,
          e: e,
          features: e.features,
        })
      );
      map.current.on('mouseenter', hurricanePointsLayerConfig.id, () => {
        map.current.getCanvas().style.cursor = 'pointer';
      });
      map.current.on('mouseleave', hurricanePointsLayerConfig.id, () => {
        map.current.getCanvas().style.cursor = 'grab';
      });
      // /Hurricanes
    } catch (e) {
      console.error('EEEE', e);
      return;
    }
  }
}
