// ChatDisasterGPT.js

import React, { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Offcanvas, Row } from 'react-bootstrap';
import ReactMarkdown from 'react-markdown';
import * as dayjs from 'dayjs';

import SOPs from 'components/DisasterGPT/SOPs';
import DataSources from 'components/DisasterGPT/DataSources';
import StylishNewTextArea from 'components/DesignSystems/New/StylishNewTextArea';
import { StylishNewButton } from 'components/DesignSystems/New/StylishNewButton';
import DrawerWrapper, { DrawerFooter } from 'components/IAP/DrawerWrapper';

import {
  setSelectedDChat,
  setCurrentSitrepId,
  cancelRun
} from 'slices/dchatSlice'

import { usePollDChat, usePostDChat, useDeleteDisasterChat, useCancelDisasterChat } from './hooks/useDisasterChats';
import './ChatDisasterGPT.css';


const ChatDisasterGPT = ({ chatDGPTSession, sitrep, toggle, selectedDatetime }) => {
  const reduxDispatch = useDispatch();
  const [input, setInput] = useState('');
  const [messages, setMessages] = useState([]);
  const [sendMessageTimestamp, setSendMessageTimestamp] = useState();
  const [docSelections, setDocSelections] = useState([]);
  const [realtimeSelections, setRealtimeSelections] = useState([]);
  const [priorStatus, setPriorStatus] = useState('')

  const dchatStatus = useSelector((state) => state.dchat.status);
  const dchat = useSelector((state) => state.dchat.dchat);
  const streamtext = useSelector((state) => state.dchat.streamtext);
  const isFetchChatDGPTLoaded = useSelector((state) => state.app.isFetchChatDGPTLoaded);

  const pollDChat = usePollDChat();
  const postDChat = usePostDChat();
  const deleteDisasterChat = useDeleteDisasterChat();

  const messagesEndRef = useRef(null);

  function sendMessage(message) {
    postDChat({
      dchat_id: dchat.id,
      message: input,
      docSelections: docSelections,
      realtimeSelections: realtimeSelections,
      selectedDatetime: selectedDatetime
    });

    setMessages([...messages, {
      role: 'USER',
      content: input,
      timestamp: new Date(),
      docSelections: docSelections,
      realtimeSelections: realtimeSelections,
      selectedDatetime: selectedDatetime,
    }]);
    setInput('');
    setSendMessageTimestamp(new Date());
  }

  useEffect(() => {
    pollDChat(sitrep);
  }, [sitrep]);

  useEffect(()=>{
    if(!!dchat)
    {
      if(dchat.status!=='Complete'&&dchat.status!=="Error")
      {
        setPriorStatus(dchat.status)
      }
      else if((dchat.status==='Complete'&&priorStatus!=='Complete')||(dchat.status==='Error'&&priorStatus!=='Error'))
      {
        setPriorStatus('Initializing')
      }
      else
      {
        setPriorStatus(dchat.status)
      }
    }
  },[dchat])

  useEffect(() => {
    if (dchat?.messages) {
      let showMsg = [...dchat.messages];
      if (streamtext && (dchatStatus === 'loading' || dchatStatus === 'ongoing')) {
        const streamMsg = {
          role: 'ASSISTANT',
          content: streamtext,
        };
        showMsg = [...showMsg, streamMsg];
      }
      setMessages(showMsg);
    }
    if (!dchat || Object.keys(dchat).length === 0) {
      setMessages([]);
    }

    if (dchat?.messages?.length) {
      const dsel = dchat.messages[dchat.messages.length - 1].docSelections;
      const rsel = dchat.messages[dchat.messages.length - 1].realtimeSelections;
      if (dsel) {
        setDocSelections(dsel);
      }
      if (rsel) {
        setRealtimeSelections(rsel);
      }
    }
  }, [dchat, streamtext]);

  useEffect(() => {
    if (messagesEndRef.current && !(dchatStatus === 'loading' || dchatStatus === 'ongoing')) {
      messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [messages]);

  function cancelRunClicked() {
    reduxDispatch(cancelRun(dchat));
  }

  function deleteSessionClicked() {
    if (dchat.id && window.confirm("Delete this DisasterChat session?")) {
      deleteDisasterChat.mutate(dchat.id);
      closeClicked();
    }
  }

  function closeClicked() {
    reduxDispatch(setSelectedDChat({}));
    reduxDispatch(setCurrentSitrepId());
    toggle();
  }

  // Make sure sitrep ID is selected if sitrep selected
  function areSelectionsValid()
  {
    if(!!realtimeSelections.length)
    {
      const sitrepSelection = realtimeSelections.find(s=>s.source==="SITREP")
      if(!!sitrepSelection && !sitrepSelection.id)
      {
        return false
      }
    }
    return true
  }

  function copyToClipboard() {
    const formattedMessages = messages.map((message) => {
      const timestamp = message.timestamp ? dayjs(message.timestamp).format('YYYY-MM-DD HH:mm') : '(No datetime provided)';
      return `${message.role.toUpperCase()} [${timestamp}]:\n${message.content}\n\n`;
    }).join('');
    
    navigator.clipboard.writeText(formattedMessages)
      .catch(err => console.error('Error copying messages: ', err));
  }

  return (
    <DrawerWrapper toggle={closeClicked} title={`DisasterChat - ${dchat?.name || '(New chat)'}`} fullscreen={false}>
      <Offcanvas.Body>
        <div>
          {messages.map((message, index) => {
            let timestamp = (index === messages.length - 1) ? sendMessageTimestamp : message.timestamp;
            if (timestamp) {
              timestamp = dayjs(timestamp).format('YYYY-MM-DD HH:mm');
            } else {
              timestamp = '(No datetime provided)';
            }
            let messageContent = message?.content?.replaceAll(/【[^】]*】/g, '');
            return (
              <div key={"dpgt-message-" + index}>
                <div><strong>{message.role.toUpperCase()}:</strong></div>
                <div>
                  <label>{timestamp}</label>
                  <div className="EventAIAnalysis-paragraph">
                    <ReactMarkdown>{messageContent}</ReactMarkdown>
                  </div>
                </div>
                <hr />
              </div>
            );
          })}
          <div ref={messagesEndRef} />
        </div>
        <Row>
          {(dchatStatus === 'loading' || dchatStatus === 'ongoing') && (
            <div>
              <h5>{priorStatus}&nbsp;<i className="fa fa-spinner fa-pulse"></i></h5>
              <div>
                <span>DisasterChat can take between 10-60 seconds to complete.</span>
              </div>
            </div>
          ) || (
            <div className="ChatDisasterGPT-input-wrap">
              <StylishNewTextArea
                type="text"
                value={input}
                disabled={(dchatStatus === 'loading' || dchatStatus === 'ongoing')}
                onChange={(e) => setInput(e.target.value)}
                onKeyPress={(e) => e.key === 'Enter' ? sendMessage(input) : null}
                placeholder={"Message DisasterGPT..."}
              />
              <div className="ChatDisasterGPT-input-button-wrap">
                <StylishNewButton
                  disabled={!isFetchChatDGPTLoaded || !areSelectionsValid()}
                  onClick={() => sendMessage(input)}
                >
                  Send
                </StylishNewButton>
              </div>
              {!areSelectionsValid() && (
                <span>Choose a SITREP to send to DisasterChat</span>
              )}
            </div>
          )}
        </Row>
        <hr />
          <Row>
            <div>
              <label>DisasterChat can make mistakes.  Always check important information.</label>
            </div>
          </Row>
        <Row>
          <DataSources
            selections={realtimeSelections}
            setSelections={setRealtimeSelections}
          />
        </Row>
        <Row>
          <SOPs
            selections={docSelections}
            setSelections={setDocSelections}
          />
        </Row>
      </Offcanvas.Body>
      <DrawerFooter>
        <div className="button-group">
          {((dchatStatus === 'loading' || dchatStatus === 'ongoing') && !!dchat) && (
            <StylishNewButton
              className="button--secondary button--reverse"
              type="button"
              onClick={() => cancelRunClicked()}
            >
              Cancel Current Run
            </StylishNewButton>
          )}

          {!!dchat && !!dchat.id && (
            <>
              <StylishNewButton
                className="button--secondary button--reverse"
                type="button"
                onClick={() => copyToClipboard()}
              >
                Copy to Clipboard
              </StylishNewButton>
              <StylishNewButton
                className="button--secondary button--reverse"
                type="button"
                onClick={() => deleteSessionClicked()}
              >
                DELETE SESSION
              </StylishNewButton>
            </>
          )}

          <StylishNewButton
            className="button--secondary button--reverse"
            type="button"
            onClick={closeClicked}
          >
            Close
          </StylishNewButton>
        </div>
      </DrawerFooter>
    </DrawerWrapper>
  );
};

export default ChatDisasterGPT;
